<template>
  <v-container class="py-7">
    <div v-if="order" class="heading mb-7">
      <span>Заказ № {{ id }}</span>
      <v-chip
        :color="getStatusColor(order.status_raw)"
        small
        dark
        class="ml-3"
        >{{ order.status }}</v-chip
      >
    </div>
    <div v-if="order && orderDetail" class="details">
      <div class="detail-item">
        <span class="order-label">Дата заказа</span>
        <span class="order-value">{{ date }}</span>
      </div>

      <div class="detail-item">
        <h2 class="heading mb-2">Состав заказа</h2>
        <div v-for="(item, i) in orderDetail.items" :key="i" class="items-list">
          <div class="item">
            <span class="order-label">{{ item.item_name }}</span>
            <span class="order-value">{{ item.total_price }} {{ $store.getters["app/currency"] }}</span>
          </div>
        </div>
      </div>

      <div class="item">
        <span class="order-label">Доставка</span>
        <span class="order-value">{{ deliveryCharge }} {{ $store.getters["app/currency"] }}</span>
      </div>

      <div class="item summary">
        <span class="heading">Сумма заказа </span>
        <span class="order-label">{{ totalPrice }}</span>
      </div>

      <!-- <div>
        <v-btn color="primary" depressed @click="addReview">Оставить отзыв</v-btn>
      </div> -->
    </div>

    <span v-else class="order-value">Не удалось получить детали заказа.</span>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import actionButtonMixin from '@/mixins/actionButton'

export default {
  name: 'HistoryDetail',

  mixins: [actionButtonMixin],

  computed: {
    actionButton() {
      return {
        active: true,
        action: this.reOrder,
        text: 'Повторить заказ',
        disabled: this.$store.state.app?.settings?.iiko,
      }
    },

    id() {
      return this.$route.params.id
    },

    order() {
      return this.ordersList.find((item) => item.order_id === this.id)
    },

    date() {
      return this.order.date_created
    },

    deliveryCharge() {
      return (
        this.orderDetail.order_data.delivery_charge
          .toString()
          .split('.')[0]
          .replace(/\D+/g, '') || 0
      )
    },

    totalPrice() {
      return this.order.total
    },

    ...mapState('user', {
      orderDetail: 'historyDetail',
      ordersList: 'history',
    }),
  },

  methods: {
    reOrder() {
      this.$store.dispatch('user/reOrder', this.id)
    },

    addReview() {
      const { commit, dispatch } = this.$store
      const params = {
        orderID: this.id,
        review: 'Тестовый отзыв',
        rating: 5,
      }
      commit('overlay/update', { active: true })
      try {
        dispatch('user/addReview', params)
      } catch (e) {
        commit('snackbar/update', { active: true, text: e.message })
      }
      commit('overlay/update', { active: false })
    },

    getStatusColor(status) {
      switch (status) {
        case 'Отменен':
          return '#EF4444'
        case 'Доставлен':
          return '#34D399'
        case 'paid':
          return '#F472B6'
        case 'Новый':
          return 'rgb(255, 118, 51)'
        case 'Принят':
          return 'rgb(201, 172, 94)'
        case 'На кухне':
          return 'rgb(193, 146, 202)'
        case 'В процессе':
          return 'rgb(1, 179, 188)'
        case 'Передан курьеру':
          return 'rgb(1, 178, 152)'
        case 'Готов к выдаче':
          return '#818CF8'
        default:
          return '#94D163'
      }
    },
  },

  async created() {
    const { commit, dispatch } = this.$store
    commit('overlay/update', { active: true })
    await dispatch('user/loadHistoryDetail', this.id)
    await dispatch('user/loadHistory')
    commit('overlay/update', { active: false })
  },
}
</script>

<style lang="scss" scoped>
//prettier-ignore
.details, .detail-item, .items-list {
  display: flex;
  flex-direction: column;
}
.details {
  gap: 10px;
}

.item {
  display: flex;
  justify-content: space-between;
}

.summary {
  margin-top: 20px;
}
.order-label {
  font-weight: 700;
}
.order-value {
  font-size: 14px;
}
</style>
